<template>
  <div>
    <div class="tbox">
      <el-row :gutter="24">
        <el-col :span="videos.length > 0 ? 18 : 24">
          <el-row class="dissjs">
            <el-col :span="24" class="qtitle">
              <div>
                <img src="~assets/Q.png" alt="" style="padding-top:8px">
              </div>
              <div>
                <span class="question">问题：</span>
              </div>
              <div class="qtt">{{ detail.infoTitle }}</div>
            </el-col>
            <el-col :span="18" class="description">
              <span>
                病情描述：
              </span>
              <span>
                {{ detail.description }}
              </span>
            </el-col>
            <el-col :span="6">
              <div class="vttime" style="text-align: right;">
                <span>{{ startTime }}</span>
                <span>
                  {{ detail.browse }}次数
                </span>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 36px">
            <el-col :span="videos.length > 0 ? 8 : 6">
              <div class="docava">
                <img :src="detail.avatar" alt="" @click="todoc(detail.doctorId)">
              </div>
              <span class="docname">{{ detail.doctorName }}</span>
              <span class="docpos">{{ detail.doctorTitle }}</span>
              <div class="jieshao">
                <span>{{ detail.hospital }}</span>
                <span>{{ detail.department }}</span>
              </div>
            </el-col>
            <el-col :span="videos.length > 0 ? 16 : 18">
              <div class="as">
                <img src="~assets/A.png" alt="">
                <span>专业解答</span>
              </div>
              <span class="nierong">
                {{ detail.content }}
              </span>
              <el-row>
                <el-col :span="22">
                  <div style="float: right">
                    <div @click="change()" style="display: flex; cursor: pointer;">
                      <div>
                        <img src="~assets/zan.svg" alt="" v-if="zan">
                        <img src="~assets/zan2.svg" alt="" v-else>
                      </div>
                      <div :style="{ color: zan ? '#8C8C8C' : '#4263EB' }" class="zan">{{ zannum }}</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row style="margin-top:30px" class="guanggao">
                <el-col>
                  <div class="_xw2rajjf48q"></div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" class="bor" v-if="videos.length > 0">
          <el-row class="lrow">
            <el-col>
              <span class="cdoc"><img src="~assets/docw.svg" alt=""></span>
            </el-col>
            <el-col v-for="(item, index) in videos" :key="index" class="videosize" style="position: relative">
              <small-videos-list-itemtest :videosItem="item" class="sv2"></small-videos-list-itemtest>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="mianbox">
      <div class="mian">
        <recommend-title :Title="'相关推荐'" :eTitle="'VOICE ANSWER'" :color="'#4263EB'" class="rectitle"></recommend-title>
        <div class="lv">
          <el-row style="margin-top:100px; width:950px" v-if="relaterecommended.length != 0">
            <el-col :span="24" v-for="project in relaterecommended" :key="project.pid" :offset="1" class="elhover">
              <long-videos-listtest :videos="project" class="now ql" :totop="true"></long-videos-listtest>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LongVideosListtest from 'components/content/videos/LongVideosListtest'
// import SmallVideosList from 'components/content/videos/SmallVideosList'
import SmallVideosListItemtest from 'components/content/videos/SmallVideosListItemtest'
// import DoctorsList from 'components/content/doctors/DoctorsList'
import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'

import { getMediadetailList, addzan } from 'network/getData'
import formateDate from 'util/formateDate'
import subString from 'util/changeString'
export default {
  name: 'VideoDetail',
  components: {
    // DoctorsList,
    SmallVideosListItemtest,
    RecommendTitle,
    LongVideosListtest
  },
  data() {
    return {
      startTime: '',
      doctors: [],
      videos: [],
      detail: [],
      relaterecommended: [],
      zannum: 0,
      zan: true,
      state: false,
      title: '文章',
      content: ''
    }
  },
  // metaInfo () {
  //   return {
  //     title: this.title + '_瑞尔安心',
  //     meta: [
  //       { name: 'keywords', content: this.title },
  //       { name: 'description', content: this.content }
  //     ]
  //   }
  // },
  methods: {
    change() {
      this.zan = !this.zan
      if (this.state === false) {
        if (this.zan) {
          this.zannum -= 1
        } else {
          this.zannum += 1
        }
        setTimeout(() => {
          this.state = true
        }, 1000)
      }
      if (this.state === true) {
        addzan(this.detail.infoId, 'qa', this.zannum).then(
          res => {
            // console.log(res)
            if (res.data.message === 'OK') {
              this.state = false
            }
          })
      }
    },
    async getMediadetailListdata(infoId, type) {
      try {
        const result = await getMediadetailList(infoId, type)
        this.videos = result.data.data
        this.detail = result.data.data.detail
        this.msg = subString(this.detail.content)
        this.videos = result.data.data.doctorWorks
        this.relatevideos = result.data.data.relatedContent
        this.relaterecommended = result.data.data.relatedScienceContent
        const d = this.detail.startTime ? new Date(this.detail.startTime) : ''
        this.zannum = this.detail.thumbsup
        this.startTime = formateDate(d)
        this.title = this.detail.infoTitle
        this.content = this.msg[0].length > 100 ? this.msg[0].slice(0, 100) : this.msg[0]
      } catch (error) {
      }
    },
    todoc(e) {
      // sessionStorage.setItem('id', e)
      // this.$router.push({ path: '/mobVideoCenterHome' })
      this.$router.push({ path: '/mobVideoCenterHome', query: { docId: e } })
    }
  },
  mounted() {
    this.getMediadetailListdata(this.$route.query.qaId, 'qa');
    (window.slotbydup = window.slotbydup || []).push({
      id: 'u6745378',
      container: '_xw2rajjf48q',
      async: true
    })
  }
}
</script>

<style scoped>
.tbox {
  /* position: absolute; */
  width: 1200px;
  /* height: 564px; */
  /* left: 120px; */
  top: 100px;
  /* background: #FAFAFA; */
  margin: 0 auto;
  margin-bottom: 100px;
  /* background: #FAFAFA; */
}

.doc {
  position: absolute;
  width: 232px;
  height: 415px;
  left: 20px;
  top: 20px;
}

.lbox {
  position: absolute;
  width: 302px;
  height: 458px;
  left: 898px;
  top: 26px;
  border: 1px solid #EAEDF0;
  box-sizing: border-box;
}

.dissjs {
  padding: 20px 0px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.75);
}

.cdoc {
  font-family: "PangMenZhengDao-3";
  font-size: 24px;
  line-height: 28px;
  color: #262626;
  margin-left: 15px;
}

.lrow {
  margin-top: 21px;
  /* margin-bottom: 24px; */
}

.sv1 {
  position: absolute;
  width: 269px;
  height: 52px;
  left: 12px;
  /* top: 61px; */
}

.sv2 {
  /* position: absolute;
  width: 269px;
  height: 52px;
  left: 12px;
  top: 315px; */
}

.vt1 {
  position: absolute;
  width: 336px;
  height: 38px;
  left: 272px;
  top: 20px;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #000000;
}

.vttime {
  color: #8C8C8C;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.vname {
  position: absolute;
  width: 580px;
  height: 28px;
  left: 272px;
  top: 459px;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}

.qtitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.qtt {
  color: #262626;
  font-size: 24px;
}

.mianbox {
  /* position: absolute; */
  position: relative;
  width: 1200px;
  /* left: 120px; */
  /* top: 714px; */
  margin: 0 auto;
}

.mian {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  /* position: absolute; */
  width: 1200px;
  /* left: 125px; */
  top: 0px;
}

.videosize {
  margin-top: 10px;
}

.lrow {
  margin-top: 21px;
  /* margin-bottom: 24px; */
}

.lv {
  margin-top: -50px;
}

.question {

  font-size: 20px;
  line-height: 32px;
  color: #F6A609;
}

.description {
  color: #595959;
  font-size: 16px;
}

.times {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  /* position: absolute; */
  width: 225px;
  height: 20px;
  left: 633px;
  top: 64px;
}

.docava {
  width: 72px;
  height: 72px;
}

.docava img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.times span {
  position: static;
  width: 51px;
  height: 20px;
  left: 144px;
  top: 0px;
  font-size: 12px;
  line-height: 20px;
  color: #BFBFBF;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 20px;
}

.bor {
  border: 1px solid #EAEDF0;
  height: 458px;
}

.doc img {
  cursor: pointer;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 124px;
  height: 20px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
}

.time span {
  width: 88px;
  margin: 0px 5px;
}

.doc {
  position: absolute;
  left: 0%;
  right: 72.12%;
  top: 27.95%;
  bottom: 57.14%;
}

.doc img {
  position: absolute;
  left: 0%;
  right: 70.25%;
  top: 0%;
  bottom: 0%;
}

.docname {
  position: absolute;
  width: 48px;
  height: 24px;
  left: 94px;
  top: 6px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.docpos {
  position: absolute;
  width: 63px;
  /* height: 20px; */
  left: 150px;
  top: 6px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #4263EB;
  color: #4263EB;
  border-radius: 4px;
  margin: 2px 10px;
  padding: 2px 4px;
}

.jieshao {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: top;
  padding: 0px;
  position: absolute;
  width: 156px;
  height: 40px;
  left: 86px;
  top: 36px;
  color: #595959;
  font-size: 12px;
  line-height: 20px;
}

.answer {
  position: absolute;
  width: 536px;
  height: 278px;
  left: 292px;
  top: 139px;
}

.zan {
  font-size: 12px;
  line-height: 22px;
  margin-left: 5px;
}

.as {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
  padding: 0px;
  /* position: absolute; */
  /* width: 108px; */
  height: 28px;
  /* left: 0px; */
  /* top: 0px; */
}

.as span {
  position: static;
  width: 80px;
  height: 28px;
  left: 28px;
  top: 0px;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.nierong {
  /* position: absolute; */
  width: 516px;
  /* height: 240px; */
  left: 20px;
  /* top: 38px; */
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.guanggao {
  width: 592px;
}
</style>
